import {DependencyList, useEffect, useState} from "react";

// overload de la fonction
// afin que si un initialState est fourni alors la valeur de retour n'est pas undefined
// voir https://www.tektutorialshub.com/typescript/function-overloading-method-overloading-typescript/
function useMemoAsync<T>(factory: (prevValue: T) => Promise<T>, deps: DependencyList | undefined, initialState: T): T;
function useMemoAsync<T = undefined>(factory: (prevValue: T) => Promise<T>, deps: DependencyList | undefined): T | undefined;

// implementation de la fonction
function useMemoAsync<T>(factory: (prevValue?: T) => Promise<T>, deps: DependencyList | undefined, initialState?: T): T | undefined {
    const [value, setValue] = useState<T | undefined>(initialState);

    useEffect(() => {
        let active = true;
        load();
        return () => { active = false; };

        async function load() {
            const res = await factory(value);
            if (!active) {
                return;
            }
            setValue(res);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    return value;
}

export default useMemoAsync;